<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="{...value,ip:valueLine.ip,target:valueLine.target}"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';

export default {
  components: {fieldsRows},
  data() {
    return {
      localIpv: [['ipv4', 'IPv4'], ['ipv6', 'IPv6']],
      isIPV4: true,
    };
  },
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine'],
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          pingLine: {
            type: 'line',
            fields: {
              ip: {
                type: 'select',
                associatedOption: this.localIpv,
                name: 'IP Version',
                fieldAttrInput: {class: 'required'},
                colAttrs: {cols: 1},
              },
              target: {
                type: 'text',
                name: 'Target',
                // fieldAttrInput: {
                //   class: "required IP Domain",
                // },
                inputFieldFormatter: 'ip4ip6ValidatedInput',
                colAttrs: {style: 'max-width:378px', class: ''},
              },
              count: {
                type: 'number',
                name: 'Count',
                colAttrs: {cols: 1},
              },
              ttl: {
                type: 'number',
                name: 'TTL',
                colAttrs: {cols: 1},
              },
              packet: {
                type: 'number',
                name: 'Packet Size',
                colAttrs: {cols: 1},
              },
              interval: {
                type: 'text',
                name: 'Interval',
                colAttrs: {cols: 1},
                fieldAttrInput: {class: 'validatePingInterval'},
              },
            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };
      if (this.isIPV4) {
        templateContent.fields.pingLine.fields.tos = {
          type: 'text',
          name: 'IPP/DSCP/TOS',
          colAttrs: {cols: 2},
          fieldAttrInput: {class: 'validateHexByte'},
        };
        templateContent.fields.pingLine.fields.df = {
          type: 'switch',
          name: 'DF',
          colAttrs: {cols: 1, class: '', style: 'position: relative; bottom: 12px;'},
        };
      }
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(index, value) {
      if (index === 'ip') {
        this.isIPV4 = value === 'ipv4';
      }
      let localValue = {...this.value};
      if (index !== 'target' && index !== 'ip') {
        this.$set(localValue, index, value);
        this.$emit('input', localValue);
      } else if (index === 'target' || index === 'ip') {
        this.$set(this.valueLine, index, value);
        this.$emit('stored-value-changed', this.valueLine);
      }
    },
  },
};
</script>